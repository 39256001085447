<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="750"
      persistent
      scrollable
    >
    <v-card
        flat class="pb-2"
      >
        <v-card-title>
          Series para el Artículo {{ articulo_codigo }} - {{ articulo_nombre }}
          <v-spacer></v-spacer>
          <v-btn icon :disabled="Math.abs(parseInt(cantidad) - seriesSelect.length) != 0"><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 pb-1">
          <!-- Info de las Cantidades para el Usuario -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <div class="text-center" v-if="parseInt(cantidad) >= seriesSelect.length">
                <p class="mb-2" style="font-size: 18px;">Cantidad a Ingresar: {{ cantidad }} - Cantidad Ingresada: {{ seriesSelect.length }} - Cantidad Pendiente: {{ parseInt(cantidad) - seriesSelect.length }}</p>
              </div>
              <div v-else>
                <p class="mb-2" style="font-size: 18px;">Cantidad a Ingresar: {{ cantidad }} - Cantidad Ingresada: {{ seriesSelect.length }} - Cantidad a Quitar: {{ seriesSelect.length - parseInt(cantidad) }}</p>
              </div>
            </v-col>
          </v-row>
          <!-- Listado de Series -->
          <v-data-table
            class="cebra elevation-2 mt-1"
            :headers="headers"
            :items="seriesSelect"
            :loading="load"
            dense
            :search="search"
            hide-default-footer
            disable-pagination
          >
            <!-- Serie y Buscador en el datatable -->
            <template v-slot:top>
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="6">
                  <v-form @submit.prevent="agregarSerie()">
                    <v-text-field
                      v-model.trim="serie"
                      ref="serieRef"
                      @blur="agregarSerie()"
                      outlined
                      dense
                      type="text"
                      hide-details
                      label="Serie"
                      v-if="ver == 0"
                    ></v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="6">
                  <SearchDataTable
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </template>
            <!-- Msj que se mostrara si no existen resultados -->
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No se agregaron artículos.
              </v-alert>
            </template>
            <!-- Acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="error" @click="quitarSerie(item)" v-on="on" v-if="ver == 0">
                    <v-icon small>far fa-times-circle</v-icon>
                  </v-btn>
                </template>
                <span>Quitar Serie</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2">
          <v-row>
            <v-col cols="6" v-if="parseInt(cantidad) - seriesSelect.length > 0">
              <SubirArchivos
                :datos="objArchivo"
                @accion="recibirCodigos"
              />
            </v-col>
            <v-col :cols="parseInt(cantidad) - seriesSelect.length > 0 ? '6': '12'" class="d-flex justify-end">
              <v-btn color="warning" @click="activo = false" class="mr-2" v-if="series.length == 0">Volver</v-btn>
              <v-btn color="success" @click="confirmarSeries()" class="mr-2" v-if="parseInt(cantidad) - seriesSelect.length == 0">Confirmar Series</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import { tipos_archivos } from '../../util/utils';
import SubirArchivos from '../util/SubirArchivos.vue';

export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable, SubirArchivos
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalIngSeries', value)
      }
    },
    articulo_codigo: {
      get() {
        return this.datos.articulo_codigo
      }
    },
    articulo_nombre: {
      get() {
        return this.datos.articulo_nombre
      }
    },
    series: {
      get() {
        return this.datos.series
      }
    },
    cantidad: {
      get() {
        return this.datos.cantidad
      }
    },
    ver: {
      get() {
        return this.datos.ver
      }
    }
  },
  data() {
    return {
      serie: '',
      headers: [
        { text: 'Serie', align: 'center', value: 'serie' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      seriesSelect: [],
      load: false,
      search: '',
      seriePrueba: 123456789012345,
      hover: false,
      objArchivo:{
        tipos: ['.txt', '.xls', '.xlsx'],
        cantidad: 1,
        color: 'info',
      },
      tipos: ['.txt'],
      data: null,
      nombre: '',
    }
  },
  methods: {
    initForm(){
      if (this.series.length > 0){
        for (let id in this.series){
          let unaSerie = {
            articulo_codigo: this.series[id].articulo_codigo,
            serie: this.series[id].serie
          }
          this.seriesSelect.push(unaSerie)
        }
      }else{
        // llenar series prueba
        //this.llenarSeries()
      }
    },
    llenarSeries(){
      for (let id = 1; id <= this.cantidad; id++){
        this.seriePrueba = this.seriePrueba + 1
        let unaSerie = {
          articulo_codigo: this.articulo_codigo,
          serie: this.seriePrueba.toString()
        }
        this.seriesSelect.push(unaSerie)
      }
    },
    limpiarForm(){
      this.serie = ''
      this.seriesSelect = []
      this.search = '';
      this.nombre = '';
    },
    agregarSerie(focus=1){
      if (this.serie != null && this.serie != undefined && this.serie.toString().length > 0){
        // ya se encuentra en el listado?
        let unaSerie = this.seriesSelect.filter(element => element.serie.toUpperCase() == this.serie.toUpperCase())
        if (unaSerie.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'La serie ya se encuentra en el listado.',
            color: 'warning',
          })
          this.serie = ''
          // focus en la serie nuevamente
          if(focus == 1) this.$refs.serieRef.focus()
          return
        }
        // ya se llegó al la cantidad esperada?
        if (this.seriesSelect.length + 1 > parseInt(this.cantidad)){
          this.$store.dispatch('show_snackbar', {
            text: 'Ya se ingresaron todas las series. No se puede ingresar más.',
            color: 'warning',
          })
          this.serie = ''
          // focus en la serie nuevamente
          if(focus == 1) this.$refs.serieRef.focus()
          return
        }
        // no existe, paso a armar mi objeto para agregar al listado
        let unArt = {
          articulo_codigo: this.articulo_codigo,
          serie: this.serie
        }
        this.seriesSelect.push(unArt)
        // limpio la serie
        this.serie = ''
        // focus en la serie nuevamente
        if(focus == 1) this.$refs.serieRef.focus()
      }
    },
    quitarSerie(item){
      let pos = this.seriesSelect.indexOf(item)
      this.seriesSelect.splice(pos, 1)
      // focus en la serie nuevamente
      this.$refs.serieRef.focus()
    },
    confirmarSeries(){
      this.$emit('setSeriesIngresadas', this.seriesSelect)
      this.activo = false
    },
    recibirCodigos(codigos){
      let n = codigos.length;
      if(n > 0){
        for(let i=0; i<n ;i++){
          if(codigos[i].toString().length > 0){
            this.serie = codigos[i].toString().trim();
            this.agregarSerie(0);
          }
        }
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    }
  },
}
</script>

<style>

</style>